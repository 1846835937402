/* eslint-disable no-unused-vars */
import { ApiBase } from '../apiBase';

/**
 * Retrieve sales order stages.
 */
export const getStagesDataForSo = (apiInstance, soNumber) => {
  return apiInstance.get(`Job/SalesOrder/${soNumber}/Stages`);
};

/**
 * Retrieve job data for the given sales order
 * @param {string} soNumber
 * @param {ApiBase} apiInstance
 * @returns {Promise<ApiResponse>}
 */
export const getProjectSummaryForSo = (apiInstance, soNumber) => {
  return apiInstance.get(`Job/SalesOrder/${soNumber}`);
};
