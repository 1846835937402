import { configureStore } from '@reduxjs/toolkit';
import dialogsReducer from './dialog/dialogsSlice';
import notificationsReducer from './notifications/notificationsSlice';
import currentUserReducer from './users/currentUserSlice';
import salesOrderReducer from './salesOrder/salesOrderSlice';
import homeReducer from './home/homeSlice';
import configReducer from './config/configSlice';

export default configureStore({
  reducer: {
    dialog: dialogsReducer,
    notifications: notificationsReducer,
    currentUser: currentUserReducer,
    salesOrder: salesOrderReducer,
    home: homeReducer,
    config: configReducer,
  },
});
