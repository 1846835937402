/**
 * Global Config
 */

/**
 * Config Keys
 */
const configKey = '__site_config';
(function () {
  if (typeof window[configKey] === 'undefined') {
    // write the configuration to a global location in a way that is not editable.

    Object.defineProperty(window, configKey, {
      value: Object.freeze({
        MSAL_CLIENTID: '%%MSAL_CLIENTID%%',
        MSAL_TENANTID: '%%MSAL_TENANTID%%',
        API_BASE_URL: '%%API_BASE_URL%%',
        CONTACT_US_URL:
          'https://teams.microsoft.com/l/app/f6405520-7907-4464-8f6e-9889e2fb7d8f?templateInstanceId=ad83a41e-b807-4895-b560-6cc2f28afe9b&environment=18a32d73-e11a-472c-b0ad-6aa712bcf920',
      }),

      configurable: false,
      enumerable: false,
      writable: false,
    });
  }
})();

/**
 * Helpers for accessing site configuration values.
 */

/**
 * Pattern for detecting placeholder values.
 * @type {RegEx}
 */
const placeholderPattern = /^%%[^%]+%%$/;

/**
 * Returns the specified value from the current configuration.
 *
 * @param {String} key Key for the value to be returned.
 * @param {Any} defaultValue Value to applied if the placeholder is detected (optional).
 *
 * @returns {Any} Configuration value.
 */
export function getConfig(key, defaultValue) {
  let value = window[configKey][key];

  if (value === `%%${key}%%`) return process.env[`REACT_APP_${key}`];

  if (
    !value ||
    ((typeof value === 'string' || value instanceof String) && placeholderPattern.test(value))
  ) {
    value = defaultValue;
  }

  return value;
}

/**
 * MSAL client identifier.
 * @type {String}
 */
export const clientId = getConfig('MSAL_CLIENTID');

/**
 * MSAL tenant identifier.
 * @type {String}
 */
export const tenantId = getConfig('MSAL_TENANTID');

/**
 * API base url.
 * @type {String}
 */
export const apiBaseUrl = getConfig('API_BASE_URL');

/**
 * Contact Us URL
 * This is a hardcoded value.
 * @type {String}
 */
export const contactUsUrl = getConfig('CONTACT_US_URL');
