/* eslint-disable no-unused-vars */
import { ApiBase } from '../apiBase';

/**
 * Retrieve user notifications.
 * @param {ApiBase} apiInstance
 * @param {string?} projectId - Optional project id.
 */
export const getNotifications = (apiInstance, projectId) => {
  return apiInstance.get(`Notifications?projectId=${projectId || ''}`);
};

export const toggleNotificationPin = (apiInstance, notificationId, userId) => {
  return apiInstance.post(`Notifications/${notificationId}/User/${userId}/Pin`);
};

export const toggleNotificationAcknowledge = (apiInstance, notificationId, userId) => {
  return apiInstance.post(`Notifications/${notificationId}/User/${userId}/Acknowledge`);
};
