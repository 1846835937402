import React from 'react';
import PropTypes from 'prop-types';

const AccordionItem = (props) => {
  // Conditional class name or style
  const containerClass = `accordion-item-container ${props.isDarkened ? 'darkened' : ''}`;

  return (
    <div className={containerClass}>
      <div className="accordion-item-header">{props.headerElement}</div>
      <div className={`accordion-item-content ${props.contentExpanded ? 'expanded' : ''}`}>
        {props.contentElement}
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  headerElement: PropTypes.element,
  contentElement: PropTypes.element,
  contentExpanded: PropTypes.bool,
  isDarkened: PropTypes.bool,
};

AccordionItem.defaultProps = {
  isDarkened: false,
};

export default AccordionItem;
