import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MoreInfoTooltip from '../MoreInfoTooltip';

const SummaryBar = (props) => {
  return (
    <div className="summary-bar-container">
      <h1>SO# {props.salesOrderId}</h1>
      <div className="supplementary-info-container">
        {/* <div className="supplementary-info-item">
          <h2>Design of Service No.</h2>
          <p>{props.designOfServiceNo}</p>
        </div>
        <div className="supplementary-info-item">
          <h2>Job Name</h2>
          <p>{props.jobName}</p>
        </div>
        <div className="supplementary-info-item">
          <h2>Well Name</h2>
          <p>{props.wellName}</p>
        </div>
        <div className="supplementary-info-item">
          <h2>Customer</h2>
          <p>{props.customer}</p>
        </div> */}
      </div>
    </div>
  );
};

SummaryBar.propTypes = {
  salesOrderId: PropTypes.string,
  designOfServiceNo: PropTypes.string,
  jobName: PropTypes.string,
  wellName: PropTypes.string,
  customer: PropTypes.string,
};

const SummaryDetails = (props) => {
  return (
    <div className="summary-details-container">
      <h1>Job Details</h1>
      <div className="details-container">
        <div className="details-column">
          <div className="details-item">
            <h2>Design of Service No.</h2>
            <p>{props.dosNumber}</p>
          </div>
          <div className="details-item">
            <h2>Job Name</h2>
            <p>{props.jobName}</p>
          </div>
          <div className="details-item">
            <h2>Well Name</h2>
            <p>{props.wellName}</p>
          </div>
          <div className="details-item">
            <h2>Customer</h2>
            <p>{props.customerName}</p>
          </div>
        </div>
        <div className="details-column">
          <div className="details-item">
            <h2>Location</h2>
            <p>{props.location}</p>
          </div>
          <div className="details-item">
            <h2>
              Risk Factor
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Risk Factor.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.riskFactor}</p>
          </div>
          <div className="details-item">
            <h2>
              Tier Level
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Tier Level.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.tierLevel}</p>
          </div>
          <div className="details-item">
            <h2>
              Job Type
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Job Type.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.jobType}</p>
          </div>
        </div>
        <div className="details-column">
          <div className="details-item">
            <h2>PLO No.</h2>
            <p>{props.ploNo}</p>
          </div>
          <div className="details-item">
            <h2>Status</h2>
            <p>{props.jobStatusCode}</p>
          </div>
          <div className="details-item">
            <h2>
              Product or Services
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Product or Services.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.productOrService}</p>
          </div>
          <div className="details-item">
            <h2>
              Project Distribution List
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Project Distribution List.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.projectDistributionList}</p>
          </div>
        </div>
        <div className="details-column">
          <div className="details-item">
            <h2>Control Point</h2>
            <p>{props.controlPoint}</p>
          </div>
          <div className="details-item">
            <h2>Job Contact</h2>
            <p>{props.jobContacts}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

SummaryDetails.propTypes = {
  customerName: PropTypes.string,
  dosNumber: PropTypes.string,
  jobName: PropTypes.string,
  wellName: PropTypes.string,
  location: PropTypes.string,
  riskFactor: PropTypes.string,
  tierLevel: PropTypes.string,
  jobType: PropTypes.string,
  ploNumber: PropTypes.string,
  jobStatusCode: PropTypes.string,
  productOrService: PropTypes.string,
  projectDistributionList: PropTypes.string,
  controlPoint: PropTypes.string,
  jobContacts: PropTypes.string,
};

const SummaryJobsTable = (props) => {
  return (
    <div className="summary-table-container">
      <h1>Jobs</h1>
      <table>
        <thead>
          <tr>
            <th>Sales Order No.</th>
            <th>Well Name</th>
            <th>Last Modified By</th>
            <th>Last Modified On</th>
          </tr>
        </thead>
        <tbody>
          {props.jobs.map((job, idx) => {
            const lastModifiedOnFormatted = job.lastModifiedOn.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timezone: 'CST',
            });
            return (
              <tr key={idx}>
                <td>{job.salesOrderNo}</td>
                <td>{job.wellName}</td>
                <td>{job.lastModifiedBy}</td>
                <td>{`${lastModifiedOnFormatted} CST`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

SummaryJobsTable.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      salesOrderNo: PropTypes.string,
      wellName: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      lastModifiedOn: PropTypes.instanceOf(Date),
    }),
  ),
};

const Summary = (props) => {
  const projectSummary = useSelector((state) => state.salesOrder.value.projectSummary);

  return (
    <div className="sales-order-summary-container">
      <div id="go-back" onClick={props.goBack}>
        <span>&#60;</span>
        Back to Project
      </div>
      <SummaryBar
        salesOrderId={projectSummary.soNumber}
        // designOfServiceNo={projectData.dosNumber}
        // jobName={projectData.jobName}
        // wellName={projectData.wellName}
        // customer={projectData.customerName}
      />
      <SummaryDetails
        customerName={projectSummary.customerName}
        dosNumber={projectSummary.dosNumber}
        jobName={projectSummary.jobName}
        wellName={projectSummary.wellName}
        location={projectSummary.location}
        riskFactor={projectSummary.riskFactor}
        tierLevel={projectSummary.tierLevel}
        jobType={projectSummary.jobType}
        ploNumber={projectSummary.ploNumber}
        jobStatusCode={projectSummary.jobStatusCode}
        productOrService={projectSummary.productOrService}
        projectDistributionList={projectSummary.projectDistributionList}
        controlPoint={projectSummary.controlPoint}
        jobContacts={projectSummary.jobContacts}
      />
      {/* <SummaryJobsTable
        jobs={[
          {
            salesOrderNo: jobData.soNumber,
            wellName: jobData.wellName,
            lastModifiedBy: jobData.modifiedBy,
            lastModifiedOn: new Date(jobData.modifiedDate),
          },
          {
            salesOrderNo: jobData.soNumber,
            wellName: jobData.wellName,
            lastModifiedBy: jobData.modifiedBy,
            lastModifiedOn: new Date(jobData.modifiedDate),
          },
          {
            salesOrderNo: jobData.soNumber,
            wellName: jobData.wellName,
            lastModifiedBy: jobData.modifiedBy,
            lastModifiedOn: new Date(jobData.modifiedDate),
          },
        ]}
      /> */}
    </div>
  );
};

Summary.propTypes = {
  goBack: PropTypes.func,
};

export default Summary;
