import ModelBase from './base';

export default class User extends ModelBase {
  constructor({ id, email, initials, name }) {
    super();
    this.id = id;
    this.email = email;
    this.initials = initials;
    this.name = name;
  }

  static fromJsonToReduxJson(json) {
    const user = new User({
      id: json.id,
      email: json.email,
      initials: json.initials,
      name: json.name,
    });
    return user.toJson();
  }
}
