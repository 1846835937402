import React from 'react';
import PropTypes from 'prop-types';
import ButtonCaret from '../../assets/icons/button_caret.svg';

const BannerProgressItemHeader = (props) => {
  return (
    <div className="banner-progress-item-header-container">
      <div className="title-collapse-container">
        <img
          className={`collapse-btn ${props.expanded ? 'expanded' : ''}`}
          src={ButtonCaret}
          alt="Button Caret"
          onClick={props.onClickExpand}
        />
        <div className="title-container">
          <div className="title">{props.step.title}</div>
          <div className="subtitle">{props.step.subtitle}</div>
        </div>
      </div>
      <div className="progress-container">
        <div className="progress-percentage">{props.percentageCompleted}%</div>
        <div className="progress-bar">
          <div className={`progress width-${props.percentageCompleted}`} />
        </div>
      </div>
    </div>
  );
};

BannerProgressItemHeader.propTypes = {
  step: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  percentageCompleted: PropTypes.number,
  onClickExpand: PropTypes.func,
  expanded: PropTypes.bool,
};

export default BannerProgressItemHeader;
