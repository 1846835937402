/* eslint-disable no-unused-vars */
import { ApiBase } from '../apiBase';

/**
 * Retrieve users for the given ids
 * @param {ApiBase} apiInstance
 * @param {string[]} userIds
 * @returns {Promise<ApiResponse>}
 */
export const getUsers = (apiInstance, userIds) => {
  const params = new URLSearchParams(userIds.map((id) => ['userIds', id]));
  return apiInstance.get(`Users/?${params}`);
};

/**
 * Retrieve the logged in user record
 * and creates one if it doesn't yet exist.
 * @param {ApiBase} apiInstance
 */
export const getCurrentUser = (apiInstance) => {
  return apiInstance.post('Users/GetOrCreate');
};
