import { createSlice } from '@reduxjs/toolkit';
import { sortTypes } from '../../../api/endpoints/projects';

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    value: {
      loading: false,
      summaryHeaderInfo: {
        totalProjects: '0',
        activeTotalJobs: '0',
      },
      projects: [
        // {
        //   projectname:
        //     'Project Name Project Name Project Name Project Name Project Name Project Name Project Name Project Name Project Name Project Name',
        //   wells: [
        //     'Well 01',
        //     'Well 02',
        //     'Well 03',
        //     'Well 04',
        //     'Well 05',
        //     'Well 06',
        //     'Well 07',
        //     'Well 08',
        //     'Well 09',
        //   ],
        //   projectId: '000000',
        //   customerName: 'John Doe',
        //   region: 'R15_Well_19',
        //   siteAddress: 'Ipsum, Lorem',
        //   statusCharts: {
        //     health: {
        //       healthy: 30,
        //       atRisk: 8,
        //       issues: 6,
        //     },
        //     progressPercentage: 25,
        //   },
        //   salesOrders: [
        //     {
        //       notificationCount: 2,
        //       soNum: '905269999',
        //       name: 'IRVING J UNIT 550H',
        //       ploNum: '12345',
        //       lastModified: '10/10/2021',
        //       health: 'Healthy',
        //       controlPoint: 'Validated',
        //       stage: 'Operations',
        //       type: 'Service',
        //     },
        //     {
        //       notificationCount: 1,
        //       soNum: '906244711',
        //       name: 'IRVING J UNIT 550H',
        //       ploNum: '12345',
        //       lastModified: '10/10/2021',
        //       health: 'Healthy',
        //       controlPoint: 'Validated',
        //       stage: 'Operations',
        //       type: 'Service',
        //     },
        //     {
        //       notificationCount: 1,
        //       soNum: '905269880',
        //       name: 'IRVING J UNIT 550H',
        //       ploNum: '12345',
        //       lastModified: '10/10/2021',
        //       health: 'Healthy',
        //       controlPoint: 'Validated',
        //       stage: 'Operations',
        //       type: 'Manufacturing',
        //     },
        //   ],
        // },
        //
      ],
      projectsPagination: {
        resultsStart: 0,
        resultsEnd: 0,
        totalResults: 0,
      },
      projectsConfig: {
        skipProjectAmt: 0,
        sortOption: {
          key: 'last-mod-newest-to-oldest',
          title: 'Last Modified Newest to Oldest',
          config: {
            sortType: sortTypes.LAST_MOD_DATE,
            reverseOrder: true,
          },
        },
        reverseOrder: true,
        searchValue: '',
      },
    },
  },
  reducers: {
    setLoading: (state, action) => {
      state.value.loading = action.payload;
    },
    setSummaryHeader: (state, action) => {
      state.value.summaryHeaderInfo = action.payload;
    },
    setProjects: (state, action) => {
      state.value.projects = [
        ...action.payload.map((project) => ({
          ...project,
          wells: project.wells.map((well) => well.name),
          salesOrders: [],
        })),
      ];
    },
    setProjectsPagination: (state, action) => {
      state.value.projectsPagination = action.payload;
    },
    setProjectsConfigSkip: (state, action) => {
      state.value.projectsConfig.skipProjectAmt = action.payload;
    },
    setProjectsConfigSortOption: (state, action) => {
      state.value.projectsConfig.sortOption = action.payload;
    },
    setProjectsConfigReverseOrder: (state, action) => {
      state.value.projectsConfig.reverseOrder = action.payload;
    },
    setProjectsConfigSearchValue: (state, action) => {
      state.value.projectsConfig.searchValue = action.payload;
    },
  },
});

export const {
  setLoading,
  setSummaryHeader,
  setProjects,
  setProjectsPagination,
  setProjectsConfigSkip,
  setProjectsConfigSortOption,
  setProjectsConfigReverseOrder,
  setProjectsConfigSearchValue,
} = homeSlice.actions;

export default homeSlice.reducer;
