import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

const ChartHeader = (props) => {
  const [chartItems, setChartItems] = useState([]);

  useEffect(() => {
    // Animate loading charts on mount of component
    const tempChartItems = JSON.parse(JSON.stringify(props.chartItems));
    for (var i = 0; i < tempChartItems.length; i++) {
      tempChartItems[i].completed = 0;
    }
    setChartItems(tempChartItems);
    setTimeout(() => {
      setChartItems(props.chartItems);
    }, 150);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chartItems]);

  return (
    <section className="chart-header">
      {props.subHeaderComponent}
      <section className="graphs">
        <div className="circular-progress-container">
          {chartItems.map((item, idx) => {
            const percentCompleted = item.total
              ? Math.ceil((item.completed / item.total) * 100)
              : 0;
            return (
              <div key={idx}>
                <section>
                  <CircularProgress
                    className="circular-progress"
                    variant="determinate"
                    value={percentCompleted}
                    thickness={4.5}
                    size={95}
                  />
                  <span id="circular-progress-text">{percentCompleted}%</span>
                </section>
                <span>{item.title}</span>
              </div>
            );
          })}
        </div>
      </section>
    </section>
  );
};

ChartHeader.propTypes = {
  chartItems: PropTypes.array,
  subHeaderComponent: PropTypes.element,
};

export default ChartHeader;
