export const MILESTONE_STATUS = {
  NotStarted: 0,
  InProgress: 0.5,
  Complete: 1,
};

export const COMPLETION_DATE_STATUS = {
  TBD: 'TBD',
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  CANCELLED: 'Cancelled',
};
