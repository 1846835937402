/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// MSAL
import { msalConfig, graphScopeRequest } from './features/auth/authConfig.js';
import { PublicClientApplication, EventType, UrlString } from '@azure/msal-browser';

// Microsoft Graph Toolkit
import { Providers as MGTProviders, SimpleProvider } from '@microsoft/mgt-element';

import App from './App';
import store from './features/state/store.js';
import { ApiProvider } from './utils/context/ApiContext.js';

// MSAL
const msalInstance = new PublicClientApplication(msalConfig);
// MGT
MGTProviders.globalProvider = new SimpleProvider(async (scopes) => {
  const token = await msalInstance.acquireTokenSilent({
    scopes: graphScopeRequest.scopes,
    account: msalInstance.getActiveAccount(),
  });
  return token.accessToken;
});

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  const tokenAcquired =
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS;

  if (tokenAcquired) {
    if (event.payload.account) {
      msalInstance.setActiveAccount(event.payload.account);
    }
  }
});

await msalInstance.initialize();

msalInstance
  .handleRedirectPromise()
  .then((result) => {
    console.log('Redirect promise handled');
    if (result) {
      // If result is truthy, your app returned from a redirect operation,
      // and it completed successfully
      console.log('Redirecting home');
      window.location.replace('/home');
    } else {
      // If .then was called but result is falsey, that means your app is not returning
      // from a redirect operation (e.g. user visiting the site for the first time)
    }
  })
  .catch((error) => {
    console.error(error);
    // If .catch is called, this means your app was returning from a redirect operation
    // and an error occurred.
    console.log('Redirect error redirecting /', error);
    window.location.replace('/');
  });

const root = ReactDOM.createRoot(document.getElementById('root'));

const isAuthPopup = () => {
  const hash = window.location.hash;
  return UrlString.hashContainsKnownProperties(hash);
};

if (isAuthPopup) {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ApiProvider msalInstance={msalInstance}>
          <BrowserRouter>
            <App instance={msalInstance} />
          </BrowserRouter>
        </ApiProvider>
      </Provider>
    </React.StrictMode>,
  );
} else {
  <h1>Authenticating...</h1>;
}
