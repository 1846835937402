import React from 'react';
import PropTypes from 'prop-types';

const ProjectItem = (props) => {
  // Conditional class name or style
  const containerClass = `project-item-container ${props.isDarkened ? 'darkened' : ''}`;

  return (
    <div className={containerClass}>
      <div className="project-item-header">{props.headerElement}</div>
    </div>
  );
};

ProjectItem.propTypes = {
  headerElement: PropTypes.element,
  isDarkened: PropTypes.bool,
};

ProjectItem.defaultProps = {
  isDarkened: false,
};

export default ProjectItem;
