/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars
import { LogLevel } from '@azure/msal-browser';
import { clientId, tenantId } from '../../globalConfig';

/**
 * Enter here the user flows and custom policies for your application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const policies = {
  authorities: {
    signUpSignIn: {
      authority: `https://login.microsoftonline.com/${tenantId}`,
    },
  },
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: policies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [], // Mark your tenant's domain as trusted.
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    tokenRenewalOffsetSeconds: 300, // Determines the window of offset needed to renew the token before expiry.
    loggerOptions: {
      logLevel: LogLevel.Warning, // Adjust the log level
      piiLoggingEnabled: false, // Set to true if you are sending your logs to a third party
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // Uncomment for debugging
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          //   case LogLevel.Info:
          //     console.info(message);
          //     return;
          // case LogLevel.Verbose:
          //   console.debug(message);
          //   return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  api: {
    scopes: {
      call: [`api://${clientId}/Api.Call`],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [...protectedResources.api.scopes.call],
};

export const appScopeRequest = {
  scopes: [`api://${clientId}/Api.Call`],
};

export const graphScopeRequest = {
  scopes: ['User.Read'],
};
