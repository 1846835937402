/* eslint-disable no-console */
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { appScopeRequest } from '../features/auth/authConfig';
import { Button } from 'react-bootstrap';

import HalLogo from '../assets/images/hal-logo.svg';
import HalTraxLogo from '../assets/images/e-completions-hal-tra-x.svg';

const Landing = () => {
  const { instance: msalInstance } = useMsal();

  const handleLogin = async () => {
    const loginRedirect = async () => {
      return await msalInstance
        .loginRedirect({
          scopes: appScopeRequest.scopes,
          redirectUri: '/',
        })
        .catch((error) => {
          console.log('Landing: Error on login redirect', error);
          window.location.reload();
        });
    };

    await loginRedirect();
  };

  return (
    <div className="landing-page-container">
      <div className="hal-title">
        <img src={HalLogo} alt="Halliburton Logo" />
      </div>
      <section className="login-container">
        <div className="title-container">
          <h1>Welcome to</h1>
          <img src={HalTraxLogo} alt="HalTrax Logo" />
        </div>
        <div className="login-cta-container">
          <UnauthenticatedTemplate>
            <Button className="landing-log-in-btn" onClick={handleLogin}>
              Login
            </Button>
          </UnauthenticatedTemplate>
        </div>
      </section>
    </div>
  );
};

export default Landing;
