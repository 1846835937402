import React from 'react';
import PropTypes from 'prop-types';
import NotificationsList from '../common/NotificationsList';
import { useNavigate, useParams } from 'react-router-dom';

const ContainerWithNotifications = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <section className="container-with-notifications">
      <div className="content">{props.content}</div>
      <aside>
        <NotificationsList
          onNotificationClick={(id) => {
            navigate(`/notifications/${id}`);
          }}
          projectId={id}
        />
      </aside>
    </section>
  );
};

ContainerWithNotifications.propTypes = {
  content: PropTypes.element,
};

export default ContainerWithNotifications;
