import SummaryHeader from '../components/home/SummaryHeader';
import { useMsal } from '@azure/msal-react';
import Projects from '../components/home/Projects';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  setLoading,
  setProjectsPagination,
  setProjects,
  setSummaryHeader,
  setProjectsConfigSkip,
  setProjectsConfigReverseOrder,
  setProjectsConfigSearchValue,
  setProjectsConfigSortOption,
} from '../features/state/home/homeSlice';
import { clearDeepLinkRoute, setBackPressed } from '../features/state/config/configSlice';
import { HOME_PAGINATION, HOME_PROJECTS_PER_PAGE } from '../constants/home';
import { useNavigate } from 'react-router-dom';
import HeadersWithProjectFilters from '../components/home/HeadersWithProjectFilters';
import { getProjects } from '../api/endpoints/projects';
import { isValidDeepLinkRoute } from '../utils/config';
import { useApi } from '../utils/context/ApiContext';

const msToTriggerSearch = 1000;

const Home = () => {
  const { apiInstance } = useApi();
  let clearTimeoutId = null;
  let activeAccount;
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { summaryHeaderInfo } = useSelector((state) => state.home.value);
  const notificationCount = useSelector(
    (state) => state.notifications.value.all.filter((n) => !n.isRead).length,
  );
  const { skipProjectAmt, sortOption, reverseOrder, searchValue } = useSelector(
    (state) => state.home.value.projectsConfig,
  );
  const backPressed = useSelector((state) => state.config.value.backPressed);
  const deepLinkRoute = useSelector((state) => state.config.value.deepLinkRoute);

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (backPressed) {
      dispatch(setBackPressed(false));
      return;
    }

    dispatch(setLoading(true));
    getProjects(
      apiInstance,
      HOME_PROJECTS_PER_PAGE,
      skipProjectAmt,
      sortOption.config.sortType,
      reverseOrder,
      searchValue,
    )
      .then((response) => {
        dispatch(
          setProjectsPagination({
            resultsStart: response.responseAsJson.resultsStart,
            resultsEnd: response.responseAsJson.resultsEnd,
            totalResults: response.responseAsJson.totalResults,
          }),
        );

        const projects = response.responseAsJson.projects.map((project) => ({
          ...project,
          statusCharts: {
            progressPercentage: project.progressPercentage,
          },
        }));
        dispatch(setProjects(projects));

        const isFilteredTotalProjects =
          summaryHeaderInfo.totalProjects > response.responseAsJson.totalResults;
        const newTotalProjects = isFilteredTotalProjects
          ? summaryHeaderInfo.totalProjects
          : response.responseAsJson.totalResults;
        dispatch(
          setSummaryHeader({
            totalProjects: newTotalProjects,
            activeTotalJobs: `*12`,
          }),
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      })
      .catch((error) => {});

    if (deepLinkRoute) {
      const _deepLinkRoute = deepLinkRoute;
      dispatch(clearDeepLinkRoute());
      if (isValidDeepLinkRoute(_deepLinkRoute)) navigate(_deepLinkRoute);
    }
  }, [
    dispatch,
    navigate,
    skipProjectAmt,
    reverseOrder,
    sortOption,
    searchValue,
    backPressed,
    instance,
    deepLinkRoute,
    summaryHeaderInfo.totalProjects,
    apiInstance,
  ]);

  const onPageChange = (direction) => {
    if (direction === HOME_PAGINATION.PREVIOUS) {
      dispatch(setProjectsConfigSkip(skipProjectAmt - HOME_PROJECTS_PER_PAGE));
    } else if (direction === HOME_PAGINATION.NEXT) {
      dispatch(setProjectsConfigSkip(skipProjectAmt + HOME_PROJECTS_PER_PAGE));
    }
  };

  const onSortChange = (sortOption) => {
    dispatch(setProjectsConfigSortOption(sortOption));
    dispatch(setProjectsConfigReverseOrder(sortOption.config.reverseOrder));
    dispatch(setProjectsConfigSkip(0));
  };

  const onSearchChange = (searchValue) => {
    // Debounce search
    if (clearTimeoutId) {
      clearTimeout(clearTimeoutId);
    }
    clearTimeoutId = setTimeout(() => {
      dispatch(setProjectsConfigSearchValue(searchValue));
      dispatch(setProjectsConfigSkip(0));
    }, msToTriggerSearch);
  };

  return (
    <div className="main-container home-container">
      <SummaryHeader
        name={activeAccount ? activeAccount.name : ''}
        notificationCount={notificationCount}
        totalProjects={parseInt(summaryHeaderInfo.totalProjects || 0)}
        activeTotalJobs={parseInt(summaryHeaderInfo.activeTotalJobs || 0)}
      />
      <HeadersWithProjectFilters
        searchValue={searchValue}
        sortOption={sortOption}
        onSearchChange={onSearchChange}
        onSortChange={onSortChange}
      />
      <Projects onPageChange={onPageChange} skipProjectAmt={skipProjectAmt} />
    </div>
  );
};

export default Home;
