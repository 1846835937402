import React from 'react';
import PropTypes from 'prop-types';

import MoreInfoIconGrey from '../assets/icons/more_info_grey.svg';
import MoreInfoIconBlack from '../assets/icons/more_info_black.svg';

// eslint-disable-next-line no-unused-vars
const icons = {
  grey: MoreInfoIconGrey,
  black: MoreInfoIconBlack,
};

const colors = ['grey', 'black'];

function MoreInfoTooltip(props) {
  return (
    <div className="more-info-tooltip-container">
      {/* Commenting out all tooltips for now */}
      {/* <img
        src={icons[props.color]}
        alt="More info"
        style={{ height: props.moreInfoIconSize, width: props.moreInfoIconSize }}
        className={`${props.className}`}
      />
      <div className="more-info-tooltip" style={{ width: props.tooltipWidth }}>
        {props.tooltipComponent}
      </div> */}
    </div>
  );
}

MoreInfoTooltip.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(colors),
  moreInfoIconSize: PropTypes.number,
  tooltipComponent: PropTypes.element,
  tooltipWidth: PropTypes.number,
};

export default MoreInfoTooltip;
