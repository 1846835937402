import { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
// MGT - Used for user profile info
import { Providers as MGTProviders, ProviderState } from '@microsoft/mgt-element';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDeepLinkRoute } from '../../features/state/config/configSlice';

export const AuthPageRouter = ({ AppRoutes }) => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deepLinkRoute = `/${window.location.pathname.split('/')?.slice(1).join('/')}`;

  useEffect(() => {
    MGTProviders.globalProvider.setState(
      inProgress !== 'none'
        ? ProviderState.Loading
        : isAuthenticated
        ? ProviderState.SignedIn
        : ProviderState.SignedOut,
    );

    const notAuthenticated = !isAuthenticated && inProgress === 'none';
    if (notAuthenticated) {
      const notLoginNorHome = deepLinkRoute !== '/' && deepLinkRoute !== '/home';
      if (notLoginNorHome) {
        dispatch(setDeepLinkRoute(deepLinkRoute));
      }
      navigate('/');
    }
  }, [isAuthenticated, inProgress, navigate, dispatch, deepLinkRoute]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        // Handle specific login success cases
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Handle specific login failure cases
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  return AppRoutes;
};
