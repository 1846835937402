import React from 'react';
import PropTypes from 'prop-types';
import eCompletionsLogo from '../../assets/icons/ecompletions_icon.svg';
// import NotificationIcon from '../../assets/icons/notification_icon.svg';
import { Person } from '@microsoft/mgt-react';

function SummaryHeader(props) {
  return (
    <div className="summary-header-container">
      <div className="logo-container">
        <img src={eCompletionsLogo} alt="eCompletions logo" />
        <span>HalTrax</span>
      </div>
      <div className="user-info-container">
        <div className="user-image-container">
          <Person personQuery="me" />
        </div>
        <div className="user-info">
          <span className="welcome-back">Welcome Back,</span>
          <span className="user-name">{props.name}</span>
          {/* <div className="notification-container">
            <img src={NotificationIcon} alt="Notifications icon" />
            <span className="notification-count">{props.notificationCount}</span>
          </div> */}
        </div>
      </div>
      <div className="summary-info-container">
        <div className="item-container">
          <span className="value">{props.totalProjects}</span>
          <span>Total Projects</span>
        </div>
        {/* <div className="item-container">
          <span className="value">{props.activeTotalJobs}</span>
          <span>Total Active Jobs</span>
        </div> */}
      </div>
    </div>
  );
}

SummaryHeader.propTypes = {
  name: PropTypes.string,
  notificationCount: PropTypes.number,
  totalProjects: PropTypes.number,
  activeTotalJobs: PropTypes.number,
};

export default SummaryHeader;
