/* eslint-disable no-console */
import { appScopeRequest } from '../features/auth/authConfig';
import { apiBaseUrl } from '../globalConfig';
import { isApiToken } from '../utils/auth';

const apiMethods = {
  GET: 'GET',
  POST: 'POST',
};

export const HTTP_STATUS_CODES = {
  OK: 200, // Success
  CREATED: 201, // Resource created successfully
  BAD_REQUEST: 400, // Invalid request
  UNAUTHORIZED: 401, // Unauthorized access
  FORBIDDEN: 403, // Access forbidden
  NOT_FOUND: 404, // Resource not found
  METHOD_NOT_ALLOWED: 405, // Method not allowed for the requested resource
  INTERNAL_SERVER_ERROR: 500, // Internal server error
  SERVICE_UNAVAILABLE: 503, // Service unavailable
};

/**
 * API Base class
 */
export class ApiBase {
  constructor(baseUrl, msalInstance, msalInitialized = false) {
    this.baseUrl = baseUrl;
    this.msalInitialized = msalInitialized;
    this.msalInstance = msalInstance;
    this.msalInstanceInProgress = false;
    this._callingApiInitializedEvents = { calling: false, tokenUsed: '' };
    this._apiInitializedEvents = [];
    this.headers = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };
  }

  initialize() {
    console.log('Initializing API');
    if (!this.msalInstance) {
      console.log('ApiBase: No msal instance found, cannot initialize');
      return;
    }
    this._callApiInitializedEvents();
  }

  loginRedirect = async () => {
    if (this.msalInstanceInProgress) return;
    this.msalInstanceInProgress = true;
    await this.msalInstance
      .loginRedirect({
        scopes: appScopeRequest.scopes,
        redirectUri: '/',
      })
      .catch((error) => {
        console.log('ApiBase: Error on login redirect', error);
        window.location.reload();
      });
    this.msalInstanceInProgress = false;
  };

  /**
   * Call function to acquire and update token silently
   * @returns {Promise<AuthenticationResult>} token - Token acquired
   */
  async acquireTokenSilent() {
    const _token = await this.msalInstance
      .acquireTokenSilent({
        scopes: appScopeRequest.scopes,
        account: this.msalInstance.getActiveAccount(),
      })
      .catch(async (e) => {
        console.log('ApiBase: Error on acquireTokenSilent, navigating to landing page', e);
        window.location.replace('/');
      });

    if (_token && _token.accessToken && isApiToken(_token)) {
      return _token;
    } else if (_token && _token.accessToken && !isApiToken(_token)) {
      console.log('ApiBase: acquireTokenSilent no valid API token found');
    }
    console.log('ApiBase: acquireTokenSilent no API token found');
    return '';
  }

  /**
   * Call an authenticated endpoint
   * @param {string} path - Endpoint to be called e.g. hello to call /hello
   * @param {string?} method - GET/POST/DELETE/PUT. GET by default
   * @param {Object?} optional - Optional parameters
   * @param {string?} optional.headers - Optional param for if needing additional headers besides the default ones that already includes the bearer token
   * @param {string?} optional.parameters - Parameters to send to request, will be parsed to json automatically, if both parameters and jsonParameters are passed, only jsonParameters will be used
   * @param {string?} optional.jsonParameters - Parameters to send to request in a json string, if both parameters and jsonParameters are passed, only jsonParameters will be used
   * @returns {Promise<Response>} response - Response of request
   */
  async callApiWithAuth(
    path,
    method,
    { headers = this.headers, parameters = null, jsonParameters = null } = {},
  ) {
    console.log('ApiBase: callApiWithAuth', path, method);
    const activeAccount = this.msalInstance.getActiveAccount();
    if (!activeAccount || !this.msalInitialized) {
      console.log('ApiBase: No msal active account found or no msal initialized');
      return;
    }

    const token = await this.acquireTokenSilent();

    if (!token) {
      console.log('ApiBase: No API token found');
      return;
    }

    var body;
    const requestUrl = this._buildUrl(path);
    if (parameters !== null) body = JSON.stringify(parameters);
    if (jsonParameters !== null) body = jsonParameters;
    let response = await fetch(requestUrl, {
      method,
      headers: { ...headers, Authorization: `Bearer ${token.accessToken}` },
      body,
    });
    return response;
  }

  /**
   * GET Request to API
   * @param {string} path - API endpoint
   * @param {Object?} headers - Optional custom headers
   * @returns {Promise<ApiResponse>} response - Response
   * @throws {ApiError} apiError - Error with info to match the OniError model from the API
   */
  get = async (path, headers = undefined) => {
    const response = await this.callApiWithAuth(path, apiMethods.GET, headers);
    return this.processNetworkRequest(response);
  };

  /**
   * POST Request to API
   * @param {string} path - API endpoint
   * @param {string?} parameters - Parameters to send to request, will be parsed to json automatically, if both parameters and jsonParameters are passed, only jsonParameters will be used
   * @param {string?} jsonParameters - Parameters to send to request in a json string, if both parameters and jsonParameters are passed, only jsonParameters will be used
   * @param {Object?} headers - Optional custom headers
   * @returns {Promise<Response>} response - Response
   * @throws {ApiError} apiError - Error with info to match the OniError model from the API
   */
  post = async (path, parameters = null, jsonParameters = null, headers = undefined) => {
    const response = await this.callApiWithAuth(path, apiMethods.POST, {
      headers,
      parameters,
      jsonParameters,
    });
    return this.processNetworkRequest(response);
  };

  /**
   * Process network request or throw error if API returns an error
   * @param {Response} request - Response from the API
   * @returns {Promise<ApiResponse>} response - Processed API response
   * @throws {ApiError} apiError - Error with info to match the OniError model from the API
   */
  processNetworkRequest = async (response) => {
    const jsonResponse = await response.json();
    if (!response.ok) {
      throw new ApiError({ message: jsonResponse.errorMessage, statusCode: response.status });
    }
    const apiResponse = new ApiResponse(jsonResponse, response.status);
    return apiResponse;
  };

  /**
   * Helper function to build the api url to be called
   * @param {string} path - Path to build
   */
  _buildUrl(path) {
    return `${this.baseUrl}${path}`;
  }

  /**
   * Add a function to be called when API is initialized
   * @param {() => void} callback - Function to be called when API is initialized
   */
  addApiInitializedEvent(callback) {
    this._apiInitializedEvents.push(callback);
  }

  /**
   * Call all functions that are listening for API initialized event
   */
  async _callApiInitializedEvents() {
    if (this._callingApiInitializedEvents.calling) return;
    this._callingApiInitializedEvents.calling = true;
    this._apiInitializedEvents.forEach((callback) => callback());
    this._callingApiInitializedEvents.calling = false;
  }
}

/**
 * Wrapper class for an API Response
 */
class ApiResponse {
  constructor(responseAsJson, statusCode) {
    this.responseAsJson = responseAsJson;
    this.statusCode = statusCode;
  }

  get ok() {
    return this.statusCode === 200;
  }
}

/**
 * Wrapper class for an API Error
 */
class ApiError {
  constructor({ title = 'Error', message, statusCode = 500 }) {
    this.title = title;
    this.message = message;
    this.statusCode = statusCode;
  }

  get ok() {
    return this.statusCode === 200;
  }
}

const ApiBaseInstance = new ApiBase(apiBaseUrl);
const headers = ApiBaseInstance.headers;

export { headers, ApiResponse, ApiError };
export default ApiBaseInstance;
