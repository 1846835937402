import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Milestone from '../../assets/icons/milestone.svg';
import StepCheckMark from '../../assets/icons/step_check_mark.svg';
import { MILESTONE_STATUS } from '../../constants/salesOrder';

const DispatchItem = (props) => {
  const renderStatusIcon = (status) => {
    switch (status) {
      case MILESTONE_STATUS.Complete:
        return (
          <span className={'status-icon'}>
            <img className={'status-icon'} src={StepCheckMark} alt="Status icon" />;
          </span>
        );
      case MILESTONE_STATUS.InProgress:
        return <span className={'status-icon circle in-progress'} />;
      case MILESTONE_STATUS.NotStarted:
      default:
        return <span className={'status-icon circle not-started'} />;
    }
  };

  let title = props.item.items[0].title;
  props.item.items.forEach((item) => {
    if (item.completionDate === MILESTONE_STATUS.Complete) {
      title = item.title;
    }
  });

  return (
    <div className="dispatch-item-container">
      <div className="title-container">
        <img src={Milestone} alt="Milestone icon" />
        <div className="title">{title}</div>
      </div>
      <div className="status-icon-container">
        {props.item.items.map((item, idx) => (
          <Fragment key={idx}>{renderStatusIcon(item.completionDate)}</Fragment>
        ))}
      </div>
    </div>
  );
};

DispatchItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    action: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        completionDate: PropTypes.string,
        lastModifiedOn: PropTypes.string,
      }),
    ),
  }),
};

export default DispatchItem;
