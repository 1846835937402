import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Landing from './pages/Landing';
import Notifications from './pages/Notifications';
import SalesOrder, { routeName as SalesOrderRoute } from './pages/SalesOrder';

export const AppRoutes = (
  <Routes>
    <Route path={`/${SalesOrderRoute}/:id`} element={<SalesOrder />} />
    <Route path="/home" element={<Home />} />
    <Route path="/notifications/:id?" element={<Notifications />} />
    <Route path="/" element={<Landing />} />
    <Route path="*" element={<Navigate replace to="/home" />} />
  </Routes>
);
