import React from 'react';
import PropTypes from 'prop-types';
import MoreInfoTooltip from '../../MoreInfoTooltip';

// CSS classes here will be added to the subheader item rendered
export const SubHeaderItemType = {
  INFO: { key: 'info', title: '' },
  JOB_STAGE: { key: 'job-stage', title: 'Project Stage' },
};

const subHeaderHints = {
  'Project ID': 'This is the project ID for this SO.',
  'Customer Name': 'This is the customer for this SO.',
  Region: 'This is the Region for this SO.',
  'Quote Count': 'This is the Quote Count for this SO.',
  'Job Count': 'This is the Job Count for this SO.',
  'Project Stage': 'This is the project stage for this SO.',
};

const subHeaderTags = {
  'Business Dev': {
    tag: 'Business Dev',
    className: 'business-development',
  },
  Technology: { tag: 'Technology', className: 'technology' },
  Manufacturing: { tag: 'Manufacturing', className: 'manufacturing' },
  Operations: { tag: 'Operations', className: 'operations' },
  'Post Job Act': { tag: 'Post Job', className: 'post-job-activities' },
};

const SubHeader = (props) => {
  const jobStageTags = props.subHeaderItems.find(
    (item) => item.type === SubHeaderItemType.JOB_STAGE.key,
  );

  return (
    <section className="sub-header">
      <h2>{props.subHeaderTitle}</h2>
      <div className="sub-header-container">
        <section id="sub-header-items">
          {props.subHeaderItems
            .filter((item) => item.type !== SubHeaderItemType.JOB_STAGE.key)
            .map((item, idx) => (
              <div key={idx} className="sub-item">
                <div className="header-item">
                  <h5>{item.title}</h5>
                  <MoreInfoTooltip
                    className="completion-more-info"
                    color={'grey'}
                    tooltipComponent={<span>{subHeaderHints[item.title]}</span>}
                    tooltipWidth={215}
                  />
                </div>
                <div className="value-container">
                  <span className={`${item.type} value`}>{item.value}</span>
                </div>
              </div>
            ))}
        </section>
        <section id="sub-header-tags">
          <div className="sub-item">
            <div className="header-item">
              <h5>{jobStageTags.title}</h5>
              <MoreInfoTooltip
                className="completion-more-info"
                color={'grey'}
                tooltipComponent={<span>{subHeaderHints[jobStageTags.title]}</span>}
                tooltipWidth={215}
              />
            </div>
            <div className="value-container">
              {jobStageTags.value.length === 0 ? (
                <span className={`value`}>{props.stagesComplete ? 'Complete' : ''}</span>
              ) : (
                jobStageTags.value.map((val, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <span
                        className={`${jobStageTags.type} value ${subHeaderTags[val].className}`}>
                        {subHeaderTags[val].tag}
                      </span>
                    </React.Fragment>
                  );
                })
              )}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

SubHeader.propTypes = {
  subHeaderTitle: PropTypes.string,
  subHeaderItems: PropTypes.array,
  stagesComplete: PropTypes.bool,
};

export default SubHeader;
