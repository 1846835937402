/* eslint-disable no-console */
import { useEffect } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PageLayout } from './components/PageLayout';
import { AuthPageRouter } from './components/auth/AuthPageRouter';
import { AppRoutes } from './AppRoutes';

import './styles/main.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'overlayscrollbars/overlayscrollbars.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useBackPressed as initializeUseBackPressed } from './utils/hooks/useBackPressed';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

const App = ({ instance }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const _activeAccount = instance.getActiveAccount();
  initializeUseBackPressed();

  useEffect(() => {
    if (_activeAccount && matchPath(pathname, '/')) {
      console.log('App: Redirecting to home, authenticated, path /');
      navigate('/home');
    }
  }, [_activeAccount, navigate, pathname]);

  return (
    <div className="App">
      <MsalProvider instance={instance}>
        <PageLayout>
          <AuthPageRouter AppRoutes={AppRoutes} />
        </PageLayout>
      </MsalProvider>
    </div>
  );
};

export default App;
