import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'config',
  initialState: {
    value: {
      backPressed: false,
      deepLinkRoute: '',
    },
  },
  reducers: {
    setBackPressed: (state, action) => {
      state.value.backPressed = action.payload;
    },
    setDeepLinkRoute: (state, action) => {
      state.value.deepLinkRoute = action.payload;
    },
    clearDeepLinkRoute: (state) => {
      state.value.deepLinkRoute = '';
    },
  },
});

export const { setBackPressed, setDeepLinkRoute, clearDeepLinkRoute } = configSlice.actions;

export default configSlice.reducer;
