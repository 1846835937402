// eslint-disable-next-line no-unused-vars
import { ApiBase, ApiResponse } from '../apiBase';

export const sortTypes = {
  LAST_MOD_DATE: 'LastModDate',
  CUSTOMER_NAME: 'CustomerName',
};

/**
 * Get home projects data.
 * @param {ApiBase} apiInstance
 * @param {number} amount - Amount of projects to return
 * @param {number} skip - Amount of projects to skip
 * @param {string} sortType - sortTypes
 * @param {boolean} reverseOrder - Reverse order
 * @param {string} searchValue - Search value
 * @returns {Promise<ApiResponse>} response - Response
 */
export const getProjects = (
  apiInstance,
  amount = 5,
  skip = 0,
  sortType = sortTypes.LAST_MOD_DATE,
  reverseOrder = false,
  searchValue = '',
) => {
  return apiInstance.get(
    `Projects?Amount=${amount}&Skip=${skip}&SortType=${sortType}&ReverseOrder=${reverseOrder}${
      searchValue ? `&ProjectSearchValue=${searchValue}` : ''
    }`,
  );
};

/**
 * Get project stages.
 * @param {ApiBase} apiInstance
 * @param {string} projectId - Project ID
 */
export const getProjectStages = (apiInstance, projectId) => {
  return apiInstance.get(`Projects/Stages?projectId=${projectId}`);
};
