import { STATUS_TYPES } from '../constants/notifications';
import ModelBase from './base';

export default class Notification extends ModelBase {
  constructor({
    id,
    isRead,
    isPinned,
    isUserMessage,
    hasAttachment,
    userName,
    timeAsJson,
    timeStamp,
    description,
    taskNameLink,
    quoteIdLink,
    status,
    sourceId,
    quoteId,
    dosNum,
    jobName,
    tierLevel,
    wellName,
    jobStage,
    projectId,
    customerName,
    region,
    quoteCount,
    jobCount,
  }) {
    super();
    this.id = id;
    this.isRead = isRead;
    this.isPinned = isPinned;
    this.isUserMessage = isUserMessage;
    this.hasAttachment = hasAttachment;
    this.userName = userName;
    this.timeAsJson = timeAsJson;
    this.timeStamp = timeStamp;
    this.description = description;
    this.taskNameLink = taskNameLink;
    this.quoteIdLink = quoteIdLink;
    this.status = status;
    this.sourceId = sourceId;
    this.quoteId = quoteId;
    this.dosNum = dosNum;
    this.jobName = jobName;
    this.tierLevel = tierLevel;
    this.wellName = wellName;
    this.jobStage = jobStage;
    this.projectId = projectId;
    this.customerName = customerName;
    this.region = region;
    this.quoteCount = quoteCount;
    this.jobCount = jobCount;
  }

  static fromJsonToReduxJson(json) {
    // Current json response
    // "id": "00000000-0000-0000-0001-000000000000",
    // "content": "Notification Content",
    // "sourceType": "User",
    // "sourceId": "00000000-0000-0000-0000-000000000001",
    // "destinationType": "User",
    // "destinationId": "00000000-0000-0000-0000-000000000001",
    // "createdDate": "2023-09-11T18:22:32.7334335Z",
    // "isAcknowledged": false,
    // "isPinned": false
    // "displayName": "Test One",
    // "status": "NONE"

    const createdDate = new Date(json.createdDate);
    const notification = new Notification({
      id: json.id,
      isRead: json.isAcknowledged,
      isPinned: json.isPinned,
      isUserMessage: json.sourceType === 'User',
      hasAttachment: false,
      userName: json.displayName,
      timeAsJson: JSON.stringify(createdDate),
      timeStamp: calcTimeDifference(createdDate),
      description: json.content,
      taskNameLink: `/project/${json.projectId || ''}`,
      quoteIdLink: `/project/${json.projectId || ''}`,
      status: STATUS_TYPES[json.status.toUpperCase()],
      sourceId: json.sourceId,
      quoteId: '*lorem',
      dosNum: json.sourceId,
      jobName: '*R15_Well_10',
      tierLevel: '*ipsum',
      wellName: '*IRVING J UNIT 550H',
      jobStage: '*Operations',
      projectId: json.projectId,
      customerName: json.customerName,
      region: json.region,
      quoteCount: json.quoteCount,
      jobCount: json.jobCount,
    });

    return notification.toJson();
  }
}

/**
 * Calculate the time difference and returns it in a formatted string.
 * @param {Date} createdDate The given time.
 * @returns {string} The formatted time difference string.
 */
function calcTimeDifference(createdDate) {
  const now = new Date();
  const timeDifferenceMs = now - createdDate;

  const msPerHour = 1000 * 60 * 60;
  const msPerDay = msPerHour * 24;

  if (timeDifferenceMs < msPerHour) {
    return 'now';
  }
  if (timeDifferenceMs < msPerDay) {
    const hoursAgo = Math.round(timeDifferenceMs / msPerHour);
    return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
  }
  if (timeDifferenceMs < msPerDay * 6) {
    return createdDate.toLocaleString([], { weekday: 'long' });
  }
  return createdDate.toLocaleString([], { month: 'numeric', day: 'numeric', year: '2-digit' });
}
