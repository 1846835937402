/* eslint-disable no-console */
import { useEffect, useState } from 'react';
// import Tabs from '../components/home/Tabs';
import Summary from '../components/home/Summary';
import JobProgress from '../components/home/JobProgress';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectStages } from '../api/endpoints/projects';
import { useDispatch } from 'react-redux';
import {
  setStages,
  reset,
  setProject,
  setProjectSummary,
} from '../features/state/salesOrder/salesOrderSlice';
import { useIsAuthenticated } from '@azure/msal-react';
import { getProjectSummaryForSo } from '../api/endpoints/salesOrder';
import { useApi } from '../utils/context/ApiContext';

export const routeName = 'project';

const SalesOrder = () => {
  const { apiInstance } = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { id } = useParams();
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const onTabClick = (tabIdx) => {
    setActiveTabIdx(tabIdx);
  };

  const goToJobProgress = () => {
    setActiveTabIdx(0);
  };

  const goToSummary = (soNumber) => {
    getProjectSummaryForSo(apiInstance, soNumber)
      .then((res) => {
        const projectSummary = res.responseAsJson;
        dispatch(setProjectSummary(projectSummary));
      })
      .catch((e) => {});
    setActiveTabIdx(1);
  };

  const _tabTitles = ['Job Progress', 'Summary'];
  const _tabComponents = [
    <JobProgress salesOrderId={id} goToSummary={goToSummary} />,
    <Summary goBack={goToJobProgress} />,
  ];

  const tabs = _tabTitles.map((tab, idx) => ({
    name: tab,
    onClick: onTabClick,
    view: _tabComponents[idx],
  }));

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (!apiInstance.msalInitialized) return; // When the user navigates directly to a project url

    setActiveTabIdx(0);
    getProjectStages(apiInstance, id)
      .then((res) => {
        const projectStagesData = res.responseAsJson;
        dispatch(
          setProject({
            name: projectStagesData.projectName,
            customerName: projectStagesData.oniQuoteStages[0]?.customerName,
            projectId: id,
            region: projectStagesData.oniQuoteStages[0]?.region,
            quoteCount: projectStagesData.oniQuoteStages.length,
            jobCount: projectStagesData.oniOperationsStages.length,
          }),
        );

        dispatch(
          setStages({
            businessDevelopment: projectStagesData.oniQuoteStages,
            technology: projectStagesData.oniTechnologyStages,
            manufacturingData: projectStagesData.oniManufacturingStages,
            operation: projectStagesData.oniOperationsStages,
            postJobActivity: projectStagesData.oniPostJobStages,
            milestones: projectStagesData.milestones,
          }),
        );
      })
      .catch((e) => {
        console.log('SalesOrder: Error on getProjectStages', e);
        if (isAuthenticated && apiInstance.msalInitialized) {
          if (!e.ok) {
            console.log('SalesOrder: Redirecting to home, invalid response');
            navigate('/'); // Invalid project, redirect to home
          }
        }
      });
  }, [id, dispatch, navigate, isAuthenticated, apiInstance]);

  return (
    <div className="sales-order-container">
      {/* <Tabs tabs={tabs} activeTabIdx={activeTabIdx} /> */}
      <section>{tabs[activeTabIdx].view}</section>
    </div>
  );
};

export default SalesOrder;
