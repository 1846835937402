import { Navbar, Dropdown, DropdownButton } from 'react-bootstrap';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Person } from '@microsoft/mgt-react';
import { loginRequest } from '../features/auth/authConfig';
import { Link, NavLink, matchPath, useLocation } from 'react-router-dom';
import { routeName as SalesOrderRoute } from '../pages/SalesOrder';

export const NavigationBar = () => {
  const { instance } = useMsal();
  const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();

  // eslint-disable-next-line no-unused-vars
  const handleLoginPopup = () => {
    const login = () =>
      instance
        .loginPopup({
          ...loginRequest,
          redirectUri: '/',
        })
        .catch((error) => {});
    login();
  };

  // eslint-disable-next-line no-unused-vars
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => {});
  };

  // eslint-disable-next-line no-unused-vars
  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  const handleLogoutPopup = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: '/', // redirects the top level app after logout
    });
  };

  const setNavLinkClasses = (paths) =>
    paths.some((path) => matchPath(path, pathname))
      ? 'navbar-button navbar-button-active'
      : 'navbar-button';

  return (
    <>
      <Navbar variant="light" className="navbar-style">
        <Link className="navbar-brand" to={isAuthenticated ? '/home' : '/'}>
          HalTrax
        </Link>
        <AuthenticatedTemplate>
          <NavLink
            className={setNavLinkClasses(['/', '/home', `/${SalesOrderRoute}/:id`])}
            to="/home">
            Home
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'navbar-button navbar-button-active' : 'navbar-button'
            }
            to="/notifications">
            Notifications
          </NavLink>
          <div className="collapse navbar-collapse justify-content-end">
            <DropdownButton
              className="navbar-circular-dropdown-button"
              variant="light"
              drop="start"
              title={<Person personQuery="me" />}>
              <Dropdown.Item as="button" onClick={handleLogoutPopup}>
                Sign Out
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </AuthenticatedTemplate>
      </Navbar>
    </>
  );
};
