import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sortTypes } from '../../api/endpoints/projects';

export const sortOptions = [
  {
    key: 'last-mod-newest-to-oldest',
    title: 'Last Modified Newest to Oldest',
    config: {
      sortType: sortTypes.LAST_MOD_DATE,
      reverseOrder: true,
    },
  },
  {
    key: 'last-mod-oldest-to-newest',
    title: 'Last Modified Oldest to Newest',
    config: {
      sortType: sortTypes.LAST_MOD_DATE,
      reverseOrder: false,
    },
  },
  {
    key: 'customer-name-a-to-z',
    title: 'Customer Name A to Z',
    config: {
      sortType: sortTypes.CUSTOMER_NAME,
      reverseOrder: false,
    },
  },
  {
    key: 'customer-name-z-to-a',
    title: 'Customer Name Z to A',
    config: {
      sortType: sortTypes.CUSTOMER_NAME,
      reverseOrder: true,
    },
  },
];

function HeadersWithProjectFilters(props) {
  const [searchValue, setSearchValue] = useState(props.searchValue);
  const [selectedSortBy, setSelectedSortBy] = useState(props.sortOption.key);

  const onSortChange = (e) => {
    const selectedSortOption = sortOptions.find((option) => option.key === e.target.value);
    setSelectedSortBy(selectedSortOption.key);
    props.onSortChange(selectedSortOption);
  };

  const onSearchChange = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
    props.onSearchChange(newSearchValue);
  };

  return (
    <div className="headers-with-project-filters">
      <div className="content">
        <h1>Projects</h1>
        <div className="filters">
          <div className="filter">
            <span>Filter</span>
            <input
              className="filter-container"
              type="text"
              placeholder="Project Name, ID, Customer, Region"
              value={searchValue}
              onChange={onSearchChange}
            />
          </div>
          <div className="filter">
            <span>Sort By</span>
            <select
              className="filter-container"
              name="type"
              id="type"
              value={selectedSortBy}
              onChange={onSortChange}>
              {sortOptions.map((sortOption) => (
                <option key={sortOption.key} value={sortOption.key}>
                  {sortOption.title}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {/* <aside>
        <h1>Notifications</h1>
      </aside> */}
    </div>
  );
}

HeadersWithProjectFilters.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default HeadersWithProjectFilters;
