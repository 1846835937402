import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { contactUsUrl } from '../globalConfig';

/**
 * Dialog to display errors
 * @param {{open: boolean, errorTitle: string, errorMessage: string, onClickOk: () => {}}} props
 * @returns
 */
export default function Error(props) {
  return (
    <Dialog open={props.open} onClose={null}>
      <DialogTitle>{props.errorTitle}</DialogTitle>
      <DialogContent>
        <p>{props.errorMessage}</p>
      </DialogContent>
      <div onClick={props.onClickOk} className="error-ok-button">
        OK
      </div>
      <div onClick={() => window.open(contactUsUrl, '_blank')} className="error-ok-button">
        Contact Support
      </div>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
