export default class ModelBase {
  // Every model should create their own fromJsonToReduxJson method, returning a JSON object
  // not a class Instance to make it work with redux toolkit
  static fromJsonToReduxJson(json) {
    throw Error('fromJsonToReduxJson method not implemented');
  }

  // Build a JSON object from the class instance
  toJson() {
    const json = {};
    Object.keys(this).forEach((key) => {
      if (this[key] !== undefined) {
        json[key] = this[key];
      }
    });
    return json;
  }

  // Will parse an array of json objects from the API to an array of redux json objects
  // matching the model it corresponds to, using its fromJsonToReduxJson method
  static fromJsonArrayToObjectReduxJsonArray(jsonArray) {
    return jsonArray.map((json) => this.fromJsonToReduxJson(json));
  }
}
