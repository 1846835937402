import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBackPressed } from '../../features/state/config/configSlice';

export const useBackPressed = () => {
  const dispatch = useDispatch();
  const backPressed = useSelector((state) => state.config.value.backPressed);

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(setBackPressed(true));
    };
  }, [dispatch]);

  return backPressed;
};
