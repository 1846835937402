import { createSlice } from '@reduxjs/toolkit';
// import { STATUS_TYPES } from '../../../constants/notifications';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    value: {
      all: [
        // {
        //   id: '1',
        //   isRead: true,
        //   isPinned: true,
        //   isUserMessage: true,
        //   hasAttachment: true,
        //   userName: 'Jack Smith',
        //   timeAsJson: JSON.stringify(new Date(2023, 7, 1, 12, 0, 0)),
        //   timeStamp: '2 hours ago',
        //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   taskNameLink: '/project/2000001796',
        //   quoteIdLink: '/project/2000001796',
        //   status: STATUS_TYPES.NONE,
        //   sourceId: 'lorem',
        //   quoteId: 'lorem',
        //   dosNum: '111214',
        //   jobName: 'R15_Well_11',
        //   tierLevel: 'ipsum',
        //   wellName: 'IRVING J UNIT 550H',
        //   jobStage: 'Operations',
        // },
        // {
        //   id: '2',
        //   isRead: false,
        //   isPinned: false,
        //   isUserMessage: false,
        //   hasAttachment: false,
        //   userName: 'Business Development',
        //   timeAsJson: JSON.stringify(new Date(2023, 7, 1, 13, 0, 0)),
        //   timeStamp: '1 hour ago',
        //   description: 'Your Quote ID1234567 is Approved by Jack Smith',
        //   taskNameLink: 'https://www.google.com',
        //   quoteIdLink: 'https://www.google.com',
        //   status: STATUS_TYPES.APPROVED,
        //   sourceId: 'lorem',
        //   quoteId: 'lorem',
        //   dosNum: '111212',
        //   jobName: 'R15_Well_12',
        //   tierLevel: 'ipsum',
        //   wellName: 'IRVING J UNIT 550H',
        //   jobStage: 'Operations',
        // },
        // {
        //   id: '3',
        //   isRead: true,
        //   isPinned: false,
        //   isUserMessage: true,
        //   hasAttachment: false,
        //   userName: 'Oliver Manson',
        //   timeStamp: '6 hours ago',
        //   timeAsJson: JSON.stringify(new Date(2023, 7, 1, 9, 0, 0)),
        //   description:
        //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   taskNameLink: 'https://www.google.com',
        //   quoteIdLink: 'https://www.google.com',
        //   status: STATUS_TYPES.NONE,
        //   sourceId: 'lorem',
        //   quoteId: 'lorem',
        //   dosNum: '111222',
        //   jobName: 'R15_Well_21',
        //   tierLevel: 'ipsum',
        //   wellName: 'IRVING J UNIT 550H',
        //   jobStage: 'Operations',
        // },
        // {
        //   id: '4',
        //   isRead: false,
        //   isPinned: false,
        //   isUserMessage: false,
        //   hasAttachment: false,
        //   userName: 'Business Development',
        //   timeStamp: '6 hours ago',
        //   timeAsJson: JSON.stringify(new Date(2023, 7, 1, 9, 0, 0)),
        //   description: 'Your Quote ID1234567 is Late',
        //   taskNameLink: 'https://www.google.com',
        //   quoteIdLink: 'https://www.google.com',
        //   status: STATUS_TYPES.LATE,
        //   sourceId: 'lorem',
        //   quoteId: 'lorem',
        //   dosNum: '111223',
        //   jobName: 'R15_Well_21',
        //   tierLevel: 'ipsum',
        //   wellName: 'IRVING J UNIT 550H',
        //   jobStage: 'Operations',
        // },
        // {
        //   id: '5',
        //   isRead: false,
        //   isPinned: true,
        //   isUserMessage: true,
        //   hasAttachment: false,
        //   userName: 'Mauricio Silva',
        //   timeStamp: '7 hours ago',
        //   timeAsJson: JSON.stringify(new Date(2023, 7, 1, 8, 0, 0)),
        //   description:
        //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   taskNameLink: 'https://www.google.com',
        //   quoteIdLink: 'https://www.google.com',
        //   status: STATUS_TYPES.NONE,
        //   sourceId: 'lorem',
        //   quoteId: 'lorem',
        //   dosNum: '111244',
        //   jobName: 'R15_Well_21',
        //   tierLevel: 'ipsum',
        //   wellName: 'IRVING J UNIT 550H',
        //   jobStage: 'Operations',
        // },
        // {
        //   id: '6',
        //   isRead: false,
        //   isPinned: false,
        //   isUserMessage: true,
        //   hasAttachment: false,
        //   userName: 'Brett Estabrook',
        //   timeAsJson: JSON.stringify(new Date(2023, 7, 1, 8, 0, 0)),
        //   timeStamp: '7 hours ago',
        //   description:
        //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   taskNameLink: 'https://www.google.com',
        //   quoteIdLink: 'https://www.google.com',
        //   status: STATUS_TYPES.NONE,
        //   sourceId: 'lorem',
        //   quoteId: 'lorem',
        //   dosNum: '111243',
        //   jobName: 'R15_Well_22',
        //   tierLevel: 'ipsum',
        //   wellName: 'IRVING J UNIT 550H',
        //   jobStage: 'Operations',
        // },
        // {
        //   id: '7',
        //   isRead: true,
        //   isPinned: false,
        //   isUserMessage: true,
        //   hasAttachment: false,
        //   userName: 'David Allison',
        //   timeAsJson: JSON.stringify(new Date(2023, 7, 1, 8, 0, 0)),
        //   timeStamp: '7 hours ago',
        //   description:
        //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   taskNameLink: 'https://www.google.com',
        //   quoteIdLink: 'https://www.google.com',
        //   status: STATUS_TYPES.NONE,
        //   sourceId: 'lorem',
        //   quoteId: 'lorem',
        //   dosNum: '111239',
        //   jobName: 'R15_Well_20',
        //   tierLevel: 'ipsum',
        //   wellName: 'IRVING J UNIT 550H',
        //   jobStage: 'Operations',
        // },
      ],
      project: [
        // {
        //   id: '1',
        //   isRead: true,
        //   isPinned: true,
        //   isUserMessage: true,
        //   hasAttachment: true,
        //   userName: 'Jack Smith',
        //   timeAsJson: JSON.stringify(new Date(2023, 7, 1, 12, 0, 0)),
        //   timeStamp: '2 hours ago',
        //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   taskNameLink: '/project/2000001796',
        //   quoteIdLink: '/project/2000001796',
        //   status: STATUS_TYPES.NONE,
        //   sourceId: 'lorem',
        //   quoteId: 'lorem',
        //   dosNum: '111214',
        //   jobName: 'R15_Well_11',
        //   tierLevel: 'ipsum',
        //   wellName: 'IRVING J UNIT 550H',
        //   jobStage: 'Operations',
        // },
      ],
    },
  },
  reducers: {
    setNotifications: (state, action) => {
      state.value.all = action.payload;
    },
    setProjectNotifications: (state, action) => {
      state.value.project = action.payload;
    },
    setIsPinned: (state, action) => {
      const { notificationId, isPinned, projectId } = action.payload;
      const notification = state.value.all?.find((n) => n.id === notificationId);
      if (notification) notification.isPinned = isPinned;
      if (projectId) {
        const projectNotification = state.value.project.find((n) => n.id === notificationId);
        if (projectNotification) projectNotification.isPinned = isPinned;
      }
    },
    setIsRead: (state, action) => {
      const { notificationId, isRead } = action.payload;
      const notification = state.value.all.find((n) => n.id === notificationId);
      notification.isRead = isRead;
    },
  },
});

export const { setNotifications, setProjectNotifications, setIsPinned, setIsRead } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
