export const getInitials = (name) => {
  const nameArray = name.split(' ');
  const initials = nameArray.map((name) => name[0]);
  return initials.join('');
};

const alphabetMap = {};
for (let i = 0; i < 26; i++) {
  alphabetMap[String.fromCharCode(65 + i)] = i + 1;
}

export const getAlphabetPosition = (letter) => {
  return alphabetMap[letter];
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
