/* eslint-disable no-console */
import { createContext, useContext, useEffect, useState } from 'react';
import { ApiBase } from '../../api/apiBase';
import { apiBaseUrl } from '../../globalConfig';

const ApiContext = createContext();

export const ApiProvider = ({ children, msalInstance }) => {
  const [apiInstance, setApiInstance] = useState(new ApiBase(apiBaseUrl, msalInstance));
  const [msalInitialized, _setMsalInitialized] = useState(false);

  useEffect(() => {
    console.log('ApiProvider: Setting apiInstance', msalInitialized);
    const newApiInstance = new ApiBase(apiBaseUrl, msalInstance, msalInitialized);
    setApiInstance(newApiInstance);
  }, [msalInstance, msalInitialized]);

  const setMsalInitialized = (initialized) => {
    console.log('ApiProvider: Setting msalInitialized', initialized);
    _setMsalInitialized(initialized);
    if (initialized) {
      apiInstance.initialize();
    }
  };

  return (
    <ApiContext.Provider
      value={{
        apiInstance, // Access msalInitialized from apiInstance
        setMsalInitialized,
      }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};
