/* eslint-disable no-console */
import { useSelector, useDispatch } from 'react-redux';
import { closeDialog } from '../features/state/dialog/dialogsSlice';

import { NavigationBar } from './NavigationBar';
import Error from './Error';
import { useEffect } from 'react';
import { getNotifications } from '../api/endpoints/notifications';
import { setNotifications } from '../features/state/notifications/notificationsSlice';
import { getUsers, getCurrentUser } from '../api/endpoints/users';
import { setCurrentUser } from '../features/state/users/currentUserSlice';

import Notification from '../models/notification';
import User from '../models/user';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useApi } from '../utils/context/ApiContext';
import { appScopeRequest } from '../features/auth/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';

export const PageLayout = (props) => {
  const { apiInstance, setMsalInitialized } = useApi();
  const { instance: msalInstance } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.dialog.value);

  const handleLogin = async () => {
    const loginRedirect = async () => {
      return await msalInstance
        .loginRedirect({
          scopes: appScopeRequest.scopes,
          redirectUri: '/',
        })
        .catch((error) => {
          console.log('PageLayout: Error on login redirect', error);
          window.location.replace('/');
        });
    };
    await loginRedirect();
  };

  useEffect(() => {
    if (!apiInstance.msalInitialized) return;
    const _getCurrentUser = () => {
      getCurrentUser(apiInstance)
        .then((response) => {
          const parsedUser = User.fromJsonToReduxJson(response.responseAsJson);
          dispatch(setCurrentUser(parsedUser));
        })
        .catch((error) => {
          console.log('Error getting current user', error);
        });
    };

    const _getNotificationsWithUsers = async () => {
      const parsedNotifications = await getNotifications(apiInstance, null)
        .then((response) =>
          Notification.fromJsonArrayToObjectReduxJsonArray(response.responseAsJson),
        )
        .catch((error) => {
          console.log('Error getting notifications', error);
        });

      if (parsedNotifications === undefined) return;

      const userIds = parsedNotifications.reduce((uIds, notification) => {
        if (notification.isUserMessage && !uIds.includes(notification.sourceId))
          uIds.push(notification.sourceId);
        return uIds;
      }, []);

      let notificationUsers = await getUsers(apiInstance, userIds)
        .then((response) => response.responseAsJson)
        .catch((error) => {
          console.log('Error getting notification users', error);
        });

      if (notificationUsers) {
        parsedNotifications.forEach((notification) => {
          const user = notificationUsers.find((user) => user.id === notification.sourceId);
          if (user) notification.userName = user.name;
        });
      }
      // Temporarily appending notifications to the end of the list for testing purposes
      // dispatch(setNotifications([...notifications, ...parsedNotifications]));
      dispatch(setNotifications(parsedNotifications));
    };
    _getCurrentUser();
    _getNotificationsWithUsers();
  }, [dispatch, apiInstance]);

  useEffect(() => {
    if (!msalInstance || !isAuthenticated) return;
    msalInstance
      .acquireTokenSilent({
        scopes: appScopeRequest.scopes,
        account: msalInstance.getActiveAccount(),
      })
      .then(async (token) => {
        setMsalInitialized(true);
      })
      .catch(async (e) => {
        console.log(e);
        if (e instanceof InteractionRequiredAuthError) {
          await handleLogin();
        } else {
          localStorage.clear();
          window.location.replace('/');
        }
      });
  }, [isAuthenticated, msalInstance, navigate]);

  return (
    <>
      {isAuthenticated && <NavigationBar />}
      {props.children}
      <Error
        open={dialog.open}
        errorTitle={dialog.title}
        errorMessage={dialog.message}
        onClickOk={() => dispatch(closeDialog())}
      />
    </>
  );
};
