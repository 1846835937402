import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChartHeader from '../ChartHeader';
import SubHeader, { SubHeaderItemType } from './SubHeader';
import ContainerWithNotifications from '../ContainerWithNotifications';
import JobSteps from './JobSteps';
import { useSelector } from 'react-redux';
import { COMPLETION_DATE_STATUS } from '../../../constants/salesOrder';
import { removeLeadingZeroes } from '../../../utils/numbers';

const JobProgress = (props) => {
  const salesOrder = useSelector((state) => state.salesOrder.value);

  const parseDate = (date) => {
    if (date === COMPLETION_DATE_STATUS.TBD) return COMPLETION_DATE_STATUS.TBD;
    return date ? new Date(date).toLocaleDateString('en-US') : COMPLETION_DATE_STATUS.TBD;
  };

  const [stepItems, setStepItems] = useState(generateStepItems(parseDate, salesOrder));

  const [chartItems, setChartItems] = useState(generateChartItems(stepItems));

  const [subHeaderItems, setSubHeaderItems] = useState(
    generateSubHeaderItems(salesOrder, chartItems),
  );

  useEffect(() => {
    setStepItems(generateStepItems(parseDate, salesOrder));
  }, [salesOrder]);

  useEffect(() => {
    setChartItems(generateChartItems(stepItems));
  }, [stepItems]);

  useEffect(() => {
    setSubHeaderItems(generateSubHeaderItems(salesOrder, chartItems));
  }, [chartItems, salesOrder]);

  return (
    <>
      <ChartHeader
        subHeaderComponent={
          <SubHeader
            subHeaderTitle={`${salesOrder.project.name}`}
            subHeaderItems={subHeaderItems}
            stagesComplete={
              chartItems.filter((item) => item.completed / item.total).length === chartItems.length
            }
          />
        }
        chartItems={chartItems}
      />
      <div className="job-steps-titles">
        <h1 className="job-steps-title">Project Stages</h1>
        <h1 className="notifications-title">Notifications</h1>
      </div>
      <ContainerWithNotifications
        content={<JobSteps steps={stepItems} goToSummary={props.goToSummary} />}
      />
    </>
  );
};

JobProgress.propTypes = {
  salesOrderId: PropTypes.string,
  goToSummary: PropTypes.func,
};

// #region Generator functions

const generateSubHeaderItems = (salesOrder, chartItems) => [
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Project ID',
    value: salesOrder.project.projectId,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Customer Name',
    value: salesOrder.project.customerName,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Region',
    value: salesOrder.project.region,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Quote Count',
    value: salesOrder.project.quoteCount,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Job Count',
    value: salesOrder.project.jobCount,
  },
  {
    type: SubHeaderItemType.JOB_STAGE.key,
    title: SubHeaderItemType.JOB_STAGE.title,
    value: chartItems
      .filter((item) => {
        const notComplete = item.completed / item.total < 1;
        const started = item.completed > 0;
        return (notComplete && started) || (notComplete && item.atLeastOneFieldStartedOnAnyItem);
      })
      .map((item) => item.title),
  },
];

const generateStepItems = (parseDate, salesOrder) => [
  {
    title: 'Business Development',
    subtitle: 'Details about your Quotes',
    itemHeaders: [
      { title: 'Quote ID', size: 25, boldValue: true },
      { title: 'Submitted to Customer Date', size: 25, countForPercentage: false },
      { title: 'Quote Validity Date', size: 25, countForPercentage: false },
      { title: 'LOI Received Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.businessDevelopment.map((item) => [
      item.quoteId,
      parseDate(item.submittedToCustomerDate),
      parseDate(item.quoteValidityDate),
      parseDate(item.loiReceivedDate),
    ]),
    rowProgress: (item) => {
      const submittedToCustomerDate = item[1];
      const quoteValidityDate = item[2];
      const loiReceivedDate = item[3];

      if (loiReceivedDate !== COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.COMPLETED;
      }

      if (quoteValidityDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      }

      const today = new Date();
      const quoteValidityDateObj = new Date(quoteValidityDate);
      if (today > quoteValidityDateObj) {
        return COMPLETION_DATE_STATUS.CANCELLED;
      }

      const atLeastOneFieldInProgress =
        parseDate(submittedToCustomerDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(quoteValidityDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(loiReceivedDate) !== COMPLETION_DATE_STATUS.TBD;

      return atLeastOneFieldInProgress
        ? COMPLETION_DATE_STATUS.IN_PROGRESS
        : COMPLETION_DATE_STATUS.TBD;
    },
    itemCompletedCount: salesOrder.stages.businessDevelopment.filter(
      (item) => parseDate(item.loiReceivedDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.businessDevelopment.find(
      (item) =>
        parseDate(item.submittedToCustomerDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.quoteValidityDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.loiReceivedDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
  // {
  //   isMilestone: true,
  //   items: salesOrder.stages.contractSigned,
  // },
  {
    title: 'Technology',
    subtitle: 'Details about your RTAs',
    itemHeaders: [
      { title: 'RTA Number', size: 25, boldValue: true },
      { title: 'Submitted', size: 25, countForPercentage: true },
      { title: 'Target Completed Date', size: 25, countForPercentage: false },
      { title: 'Actual Completed Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.technology.map((item) => [
      item.rtaNumber,
      parseDate(item.submittedDate),
      parseDate(item.targetCompletedDate),
      parseDate(item.actualCompletedDate),
    ]),
    rowProgress: (item) => {
      const submittedDate = item[1];
      const targetCompletedDate = item[2];
      const actualCompletedDate = item[3];

      if (parseDate(actualCompletedDate) !== COMPLETION_DATE_STATUS.TBD)
        return COMPLETION_DATE_STATUS.COMPLETED;

      const atLeastOneFieldInProgress =
        parseDate(submittedDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(targetCompletedDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(actualCompletedDate) !== COMPLETION_DATE_STATUS.TBD;

      return atLeastOneFieldInProgress
        ? COMPLETION_DATE_STATUS.IN_PROGRESS
        : COMPLETION_DATE_STATUS.TBD;
    },
    itemCompletedCount: salesOrder.stages.technology.filter(
      (item) => parseDate(item.actualCompletedDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.technology.find(
      (item) =>
        parseDate(item.submittedDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.targetCompletedDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.actualCompletedDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
  {
    title: 'Manufacturing',
    subtitle: 'Details about your Manufacturing Sales Orders',
    itemHeaders: [
      { title: 'SO', size: 20, boldValue: true },
      { title: 'Line Item', size: 20 },
      { title: 'Material Number', size: 20 },
      { title: 'Target Ex Works', size: 20, countForPercentage: true },
      { title: 'Projected Complete Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.manufacturingData.map((item) => [
      item.soNumber,
      item.soItemNumber,
      removeLeadingZeroes(item.materialNumber),
      parseDate(item.targetExWorksCompleteDate),
      parseDate(item.projectedCompleteDate),
    ]),
    rowProgress: (item) => {
      const targetExWorksCompleteDate = item[3];
      const projectedCompleteDate = item[4];

      if (
        parseDate(targetExWorksCompleteDate) !== COMPLETION_DATE_STATUS.TBD &&
        parseDate(projectedCompleteDate) !== COMPLETION_DATE_STATUS.TBD
      )
        return COMPLETION_DATE_STATUS.COMPLETED;

      const atLeastOneFieldInProgress =
        parseDate(targetExWorksCompleteDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(projectedCompleteDate) !== COMPLETION_DATE_STATUS.TBD;

      return atLeastOneFieldInProgress
        ? COMPLETION_DATE_STATUS.IN_PROGRESS
        : COMPLETION_DATE_STATUS.TBD;
    },
    itemCompletedCount: salesOrder.stages.manufacturingData.filter(
      (item) =>
        parseDate(item.targetExWorksCompleteDate) !== COMPLETION_DATE_STATUS.TBD &&
        parseDate(item.projectedCompleteDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.manufacturingData.find(
      (item) =>
        parseDate(item.targetExWorksCompleteDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.projectedCompleteDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
  {
    title: 'Operations',
    subtitle: 'Details about your Jobs',
    itemHeaders: [
      { title: 'SO', size: 15, boldValue: true, link: true },
      { title: 'DOS', size: 20 },
      { title: 'Job ID', size: 20 },
      { title: 'Est. Job Timing', size: 20 },
      { title: 'Actual on Location Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.operation.map((item) => [
      item.soNumber,
      item.dosNumber,
      item.jobId,
      item.jobTiming,
      parseDate(item.actualOnLocationDate),
    ]),
    rowProgress: (item) => {
      const actualOnLocationDate = item[4];

      if (parseDate(actualOnLocationDate) !== COMPLETION_DATE_STATUS.TBD)
        return COMPLETION_DATE_STATUS.COMPLETED;

      const atLeastOneFieldInProgress =
        parseDate(actualOnLocationDate) !== COMPLETION_DATE_STATUS.TBD;

      return atLeastOneFieldInProgress
        ? COMPLETION_DATE_STATUS.IN_PROGRESS
        : COMPLETION_DATE_STATUS.TBD;
    },
    itemCompletedCount: salesOrder.stages.operation.filter(
      (item) => parseDate(item.actualOnLocationDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.operation.find(
      (item) => parseDate(item.actualOnLocationDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
  {
    isMilestone: true,
    items: salesOrder.stages.dispatchedToField,
  },
  {
    title: 'Post Job Activities',
    subtitle: 'Details about your Project Closeout',
    itemHeaders: [
      { title: 'SO', size: 25, boldValue: true },
      { title: 'DOS', size: 25 },
      { title: 'Job ID', size: 25 },
      { title: 'Job Close Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.postJobActivity.map((item) => [
      item.soNumber,
      item.dosNumber,
      item.jobId,
      parseDate(item.jobCloseDate),
    ]),
    rowProgress: (item) => {
      const jobCloseDate = item[3];

      if (parseDate(jobCloseDate) !== COMPLETION_DATE_STATUS.TBD)
        return COMPLETION_DATE_STATUS.COMPLETED;

      const atLeastOneFieldInProgress = parseDate(jobCloseDate) !== COMPLETION_DATE_STATUS.TBD;

      return atLeastOneFieldInProgress
        ? COMPLETION_DATE_STATUS.IN_PROGRESS
        : COMPLETION_DATE_STATUS.TBD;
    },
    itemCompletedCount: salesOrder.stages.postJobActivity.filter(
      (item) => parseDate(item.jobCloseDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.postJobActivity.find(
      (item) => parseDate(item.jobCloseDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
];

const generateChartItems = (stepItems) => [
  {
    title: 'Business Dev',
    completed: stepItems.find((item) => item.title === 'Business Development').itemCompletedCount,
    total: stepItems.find((item) => item.title === 'Business Development').itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find((item) => item.title === 'Business Development')
      .atLeastOneFieldStartedOnAnyItem,
  },
  {
    title: 'Technology',
    completed: stepItems.find((item) => item.title === 'Technology').itemCompletedCount,
    total: stepItems.find((item) => item.title === 'Technology').itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find((item) => item.title === 'Technology')
      .atLeastOneFieldStartedOnAnyItem,
  },
  {
    title: 'Manufacturing',
    completed: stepItems.find((item) => item.title === 'Manufacturing').itemCompletedCount,
    total: stepItems.find((item) => item.title === 'Manufacturing').itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find((item) => item.title === 'Manufacturing')
      .atLeastOneFieldStartedOnAnyItem,
  },
  {
    title: 'Operations',
    completed: stepItems.find((item) => item.title === 'Operations').itemCompletedCount,
    total: stepItems.find((item) => item.title === 'Operations').itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find((item) => item.title === 'Operations')
      .atLeastOneFieldStartedOnAnyItem,
  },
  {
    title: 'Post Job Act',
    completed: stepItems.find((item) => item.title === 'Post Job Activities').itemCompletedCount,
    total: stepItems.find((item) => item.title === 'Post Job Activities').itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find((item) => item.title === 'Post Job Activities')
      .atLeastOneFieldStartedOnAnyItem,
  },
];

// #endregion Generator functions

export default JobProgress;
