import React, { useEffect, useRef, useState } from 'react';
// import { Doughnut } from 'react-chartjs-2';
import AccordionItem from '../common/AccordionItem';
import PropTypes from 'prop-types';

import ButtonCaret from '../../assets/icons/button_caret.svg';
import NotificationIcon from '../../assets/icons/notification_icon_black.svg';
import WellIcon from '../../assets/icons/well_icon_black.svg';
// import MoreInfoIcon from '../../assets/icons/more_info_grey.svg';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useWindowSize } from '../../utils/hooks/useWindowSize';
// import { padNumber } from '../../utils/numbers';
import { HOME_PAGINATION } from '../../constants/home';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/naming';
import ProjectItem from '../common/ProjectItem';
import MoreInfoTooltip from '../MoreInfoTooltip';
import { routeName as SalesOrderRoute } from '../../pages/SalesOrder';

// const doughnutOptions = {
//   cutout: '70%',
//   hover: {
//     mode: null,
//   },
//   rotation: 45,
//   plugins: {
//     tooltip: {
//       enabled: false,
//     },
//     legend: {
//       display: false,
//     },
//   },
// };

const ProjectHeader = (props) => {
  const loading = useSelector((state) => state.home.value.loading);
  const [percentage, setPercentage] = useState(props.statusCharts.progressPercentage);
  const titleRef = useRef();
  const titleContainerRef = useRef();
  const windowSize = useWindowSize();
  const [notificationCount, setNotificationCount] = useState(props.notificationCount || 0);

  useEffect(() => {
    setNotificationCount(props.notificationCount);
  }, [props.notificationCount]);

  // const [data] = useState({
  //   labels: ['Issues', 'At Risk', 'Healthy'],
  //   datasets: [
  //     {
  //       label: '# of Votes',
  //       // TODO: Remove this asterisk cleanup after we get rid of test data
  //       data: [
  //         props.statusCharts.health.issues.toString().replace('*', ''),
  //         props.statusCharts.health.atRisk.toString().replace('*', ''),
  //         props.statusCharts.health.healthy.toString().replace('*', ''),
  //       ],
  //       backgroundColor: ['#cc0000', '#f7b500', '#5fa5ee'],
  //       borderWidth: 0,
  //     },
  //   ],
  // });

  useEffect(() => {
    // Animate loading charts on mount of component
    if (!loading) return;
    setPercentage(0);
    setTimeout(() => {
      setPercentage(props.statusCharts.progressPercentage);
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEllipsisActive = (title, container) => {
    return container.offsetWidth <= title.offsetWidth;
  };
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsOverflowing(isEllipsisActive(titleRef.current, titleContainerRef.current));
    });
  }, [titleRef, titleContainerRef, windowSize]);

  return (
    <div className="project-header-container">
      <div className="info-content">
        <div ref={titleContainerRef} className="title">
          <Link ref={titleRef} id="title" to={`/${SalesOrderRoute}/${props.projectId}`}>
            {props.title}
          </Link>
        </div>
        <div className={`title-tooltip-content ${isOverflowing ? 'display-tooltip' : ''}`}>
          {props.title}
        </div>
        <div className="info">
          <div className="info-container">
            <div className="status-container">
              <div className="status">
                <img src={NotificationIcon} alt="Notification icon" />
                <span className="count">{notificationCount}</span>
              </div>
              <div className="status well-tooltip">
                <img src={WellIcon} alt="Well icon" />
                <span className="count well">{props.wells.length}</span>
                {props.wells.length > 0 && (
                  <div className="well-tooltip-content">
                    {props.wells.map((well, idx) => (
                      <span key={idx}>{well}</span>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="info">
              <div className="info-container">
                <div className="info-item">
                  <div className="title">
                    <span>Project ID</span>
                  </div>
                  <span>{props.projectId}</span>
                </div>
                <div className="info-tooltip project-tooltip">
                  <MoreInfoTooltip
                    className="completion-more-info"
                    color={'grey'}
                    tooltipComponent={<span>This is the Project ID.</span>}
                    tooltipWidth={215}
                  />
                </div>
              </div>
              <div className="info-container">
                <div className="info-item">
                  <div className="title">
                    <span>Customer Name</span>
                  </div>
                  <span>{props.customerName}</span>
                </div>
                <div className="info-tooltip customer-tooltip">
                  <MoreInfoTooltip
                    className="completion-more-info"
                    color={'grey'}
                    tooltipComponent={<span>This is the Customer Name.</span>}
                    tooltipWidth={215}
                  />
                </div>
              </div>
              <div className="info-container">
                <div className="info-item">
                  <div className="title">
                    <span>Region</span>
                  </div>
                  <span>{props.region}</span>
                </div>
                <div className="info-tooltip region-tooltip">
                  <MoreInfoTooltip
                    className="completion-more-info"
                    color={'grey'}
                    tooltipComponent={<span>This is the Region.</span>}
                    tooltipWidth={215}
                  />
                </div>
              </div>
              {/* <div className="info-item">
                <div className="title">
                  <span>Site Address</span>
                  <img src={MoreInfoIcon} alt="More info" />
                </div>
                <span>{props.siteAddress}</span>
              </div> */}
            </div>
          </div>
          <div className="progress-container">
            {/*  <div className="item health-tooltip"> */}
            {/* <Doughnut data={data} options={doughnutOptions} /> */}
            {/* <span>Health</span> */}
            {/* <div className="health-tooltip-content">
                <div className="health-tooltip-item">
                  <span>{padNumber(props.statusCharts.health.healthy)}</span>
                  <span className="label healthy">Healthy</span>
                </div>
                <div className="health-tooltip-item">
                  <span>{padNumber(props.statusCharts.health.atRisk)}</span>
                  <span className="label at-risk">At Risk</span>
                </div>
                <div className="health-tooltip-item">
                  <span>{padNumber(props.statusCharts.health.issues)}</span>
                  <span className="label issues">Issues</span>
                </div>
              </div>
            </div>*/}
            <div className="item">
              <section className="graph">
                <CircularProgress
                  className="circular-progress"
                  variant="determinate"
                  value={percentage}
                  thickness={6.1}
                  size={97}
                />
                <span id="circular-progress-text">{percentage}%</span>
              </section>
              <span>Progress</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectHeader.propTypes = {
  title: PropTypes.string,
  wells: PropTypes.arrayOf(PropTypes.string),
  projectId: PropTypes.string,
  customerName: PropTypes.string,
  region: PropTypes.string,
  siteAddress: PropTypes.string,
  statusCharts: PropTypes.shape({
    health: PropTypes.shape({
      healthy: PropTypes.number,
      atRisk: PropTypes.number,
      issues: PropTypes.number,
    }),
    progressPercentage: PropTypes.number,
  }),
  notificationCount: PropTypes.number,
};

const ProjectContentItem = (props) => {
  let isManufacturing = props.salesOrders.some((so) => so.type.includes('Manufacturing'));
  return (
    <div className="project-content-container">
      <table className="project-content-table">
        <colgroup>
          <col span={1} className="notifications-column" />
          <col span={1} className="so-column" />
        </colgroup>
        <thead>
          <tr>
            <th className="notification">
              <img src={NotificationIcon} alt={'Notification icon'} />
            </th>
            <th>SO#</th>
            <th>Well Name</th>
            {isManufacturing ? '' : <th>PLO#</th>}
            <th>Last Modified</th>
            {/* <th>Health</th> */}
            {isManufacturing ? '' : <th>Control Point</th>}
            {isManufacturing ? '' : <th>Stage</th>}
          </tr>
        </thead>
        <tbody>
          {props.salesOrders.map((so, idx) => {
            const lastDateModifiedAsString = new Date(so.lastModified).toLocaleDateString();
            return (
              <tr key={idx}>
                <td className="notifications">
                  <span>{so.notificationCount}</span>
                </td>
                <td className="so-number">
                  <span>
                    <Link to={`/${SalesOrderRoute}/${props.projectId}`}>{so.soNum}</Link>
                  </span>
                </td>
                <td>{so.name}</td>
                {isManufacturing ? '' : <td>{so.ploNum}</td>}
                <td>{lastDateModifiedAsString}</td>
                {/* {isManufacturing ? (
                  ''
                ) : (
                  <td className="status-indicator">
                    <span className="healthy">{so.health}</span>
                  </td>
                )} */}
                {isManufacturing ? (
                  ''
                ) : (
                  <td className="status-indicator">
                    <span
                      className={
                        so.controlPoint
                          ? `control-point ${so.controlPoint.replace('*', '').toLowerCase()}`
                          : ''
                      }>
                      {capitalizeFirstLetter(so.controlPoint)}
                    </span>
                  </td>
                )}
                {isManufacturing ? (
                  ''
                ) : (
                  <td className="status-indicator">
                    <span className="operations">{so.stage}</span>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

ProjectContentItem.propTypes = {
  salesOrders: PropTypes.arrayOf(
    PropTypes.shape({
      notificationCount: PropTypes.number,
      soNum: PropTypes.string,
      name: PropTypes.string,
      ploNum: PropTypes.string,
      lastModified: PropTypes.string,
      health: PropTypes.string,
      controlPoint: PropTypes.string,
      stage: PropTypes.string,
    }),
  ),
};

const ProjectContent = (props) => {
  const [manufacturingExpanded, setManufacturingExpanded] = useState(false);
  const [setServiceExpanded, setSetServiceExpanded] = useState(false);

  const manufacturingSalesOrders = props.salesOrders.filter((salesOrder) =>
    salesOrder.type.includes('Manufacturing'),
  );
  const serviceSalesOrders = props.salesOrders.filter((salesOrder) =>
    salesOrder.type.includes('Service'),
  );

  return (
    <div className="project-content">
      <hr />
      <AccordionItem
        headerElement={
          <div>
            <h3>
              <img
                className={`collapse-btn ${manufacturingExpanded ? 'expanded' : ''}`}
                src={ButtonCaret}
                alt="Button Caret"
                onClick={() => setManufacturingExpanded(!manufacturingExpanded)}
              />
              Manufacturing
            </h3>
          </div>
        }
        contentExpanded={manufacturingExpanded}
        contentElement={
          manufacturingSalesOrders.length > 0 ? (
            <ProjectContentItem
              salesOrders={manufacturingSalesOrders}
              projectId={props.projectId}
            />
          ) : (
            <div className="no-data">None at this time</div>
          )
        }
      />
      <hr />
      <AccordionItem
        headerElement={
          <h3>
            <img
              className={`collapse-btn ${setServiceExpanded ? 'expanded' : ''}`}
              src={ButtonCaret}
              alt="Button Caret"
              onClick={() => setSetServiceExpanded(!setServiceExpanded)}
            />
            Service
          </h3>
        }
        contentExpanded={setServiceExpanded}
        contentElement={
          serviceSalesOrders.length > 0 ? (
            <ProjectContentItem salesOrders={serviceSalesOrders} projectId={props.projectId} />
          ) : (
            <div className="no-data">None at this time</div>
          )
        }
      />
    </div>
  );
};

ProjectContent.propTypes = {
  salesOrders: PropTypes.arrayOf(
    PropTypes.shape({
      notificationCount: PropTypes.number,
      soNum: PropTypes.string,
      name: PropTypes.string,
      ploNum: PropTypes.string,
      lastModified: PropTypes.string,
      health: PropTypes.string,
      controlPoint: PropTypes.string,
      stage: PropTypes.string,
    }),
  ),
  projectId: PropTypes.string,
};

const Project = (props) => {
  const [isLoadingExpandedData, setIsLoadingExpandedData] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: Clean up this, we're not expanding and retrieving service headers anymore
    if (props.projectExpanded) {
      setIsLoadingExpandedData(true);
      Promise.all([
        // getProjectManufacturingHeaders(props.project.projectId)
        //   .then((response) => {
        //     let massagedData = response.responseAsJson.map((header) => {
        //       return {
        //         soNum: header.docId,
        //         name: header.stgWellDesc,
        //         lastModified: header.modDate,
        //         type: '*Manufacturing',
        //         health: '*Healthy',
        //         controlPoint: '*New',
        //         notificationCount: '*3',
        //         stage: '*Operations',
        //       };
        //     });
        //     dispatch(
        //       setProjectManufacturingHeaders({
        //         projectId: props.project.projectId,
        //         manufacturingHeaders: massagedData,
        //       }),
        //     );
        //   })
        //   .catch((error) => {}),
        // getProjectServiceHeaders(props.project.projectId)
        //   .then((response) => {
        //     let massagedData = response.responseAsJson.map((header) => {
        //       let calculatedControlPoint =
        //         header.controlPoints?.length > 0 ? header.controlPoints[0].name : '';
        //       header.controlPoints?.some((cp) => {
        //         calculatedControlPoint = cp.name;
        //         if (cp['stepsComplete'] < cp['totalSteps']) {
        //           return true; // Closest to the top, break
        //         }
        //         return false; // Continue checking other control points
        //       });
        //       return {
        //         soNum: header.soNumber,
        //         projectId: props.project.projectId,
        //         name: header.wellName,
        //         lastModified: header.modDate,
        //         type: '*Service',
        //         health: '*Healthy',
        //         ploNum: '*12345',
        //         controlPoint: calculatedControlPoint,
        //         notificationCount: '*3',
        //         stage: header.state,
        //       };
        //     });
        //     dispatch(
        //       setProjectServiceHeaders({
        //         projectId: props.project.projectId,
        //         serviceHeaders: massagedData,
        //       }),
        //     );
        //   })
        //   .catch((error) => {}),
      ])
        .finally(() => setIsLoadingExpandedData(false))
        .catch((error) => {});
    }
  }, [props.projectExpanded, props.project.projectId, dispatch]);

  return (
    <ProjectItem
      isDarkened={isLoadingExpandedData}
      headerElement={
        <ProjectHeader
          onClickExpand={() => props.handleProjectExpand(props.idx)}
          expanded={props.projectExpanded}
          title={props.project.projectname}
          statusCharts={props.project.statusCharts}
          wells={props.project.wells}
          projectId={props.project.projectId}
          customerName={props.project.customerName}
          region={props.project.region}
          // siteAddress={project.siteAddress}
          notificationCount={props.project.notificationCount}
        />
      }
      // contentElement={
      //   <ProjectContent
      //     salesOrders={props.project.salesOrders}
      //     projectId={props.project.projectId}
      //   />
      // }
      // contentExpanded={props.projectExpanded}
    />
  );
};

Project.propTypes = {
  idx: PropTypes.number,
  project: PropTypes.shape({
    title: PropTypes.string,
    wells: PropTypes.arrayOf(PropTypes.string),
    projectId: PropTypes.string,
    customerName: PropTypes.string,
    region: PropTypes.string,
    siteAddress: PropTypes.string,
    statusCharts: PropTypes.shape({
      health: PropTypes.shape({
        healthy: PropTypes.number,
        atRisk: PropTypes.number,
        issues: PropTypes.number,
      }),
      progressPercentage: PropTypes.number,
    }),
    salesOrders: PropTypes.arrayOf(
      PropTypes.shape({
        notificationCount: PropTypes.number,
        soNum: PropTypes.string,
        name: PropTypes.string,
        ploNum: PropTypes.string,
        lastModified: PropTypes.string,
        health: PropTypes.string,
        controlPoint: PropTypes.string,
        stage: PropTypes.string,
      }),
    ),
  }),
  projectExpanded: PropTypes.bool,
  handleProjectExpand: PropTypes.func,
  loading: PropTypes.bool,
};

const Projects = (props) => {
  const { loading, projects, projectsPagination } = useSelector((state) => state.home.value);

  const [projectExpanded, setProjectExpanded] = useState([]);

  useEffect(() => {
    setProjectExpanded(new Array(projects.length).fill(false));
  }, [projectsPagination, projects.length]);

  const handleProjectExpand = (idx) => {
    setProjectExpanded(
      projectExpanded.map((_, i) => (i === idx ? !projectExpanded[i] : projectExpanded[i])),
    );
  };

  const loadingClass = loading ? 'loading' : '';

  const firstPage = projectsPagination.resultsStart === 1;
  const lastPage = projectsPagination.resultsEnd === projectsPagination.totalResults;
  const emptyResults = projectsPagination.totalResults === 0;
  const disablePrevious = firstPage || loading || emptyResults;
  const disableNext = lastPage || loading || emptyResults;

  const totalResults = projectsPagination.totalResults;
  const resultsEnd = projectsPagination.resultsEnd;
  const resultsStart =
    projectsPagination.resultsStart > totalResults ? totalResults : projectsPagination.resultsStart;
  return (
    <>
      <div className={`home-projects-container ${loadingClass}`}>
        {projects.map((project, idx) => (
          <Project
            key={idx + props.skipProjectAmt + project.projectId}
            idx={idx}
            project={project}
            projectExpanded={projectExpanded[idx]}
            handleProjectExpand={handleProjectExpand}
            loading={loading}
          />
        ))}
      </div>
      <div className="home-pagination-container">
        <div className="pagination">
          <button
            className="pagination-btn pagination-btn-left"
            disabled={disablePrevious}
            onClick={() => {
              props.onPageChange(HOME_PAGINATION.PREVIOUS);
            }}>
            <span className="material-icons">Previous</span>
          </button>
          <span className="pagination-text">
            {`Showing ${resultsStart}-${resultsEnd} of ${totalResults} projects`}
          </span>
          <button
            className="pagination-btn pagination-btn-right"
            disabled={disableNext}
            onClick={() => {
              props.onPageChange(HOME_PAGINATION.NEXT);
            }}>
            <span className="material-icons">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};

Projects.propTypes = {
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
  skipProjectAmt: PropTypes.number,
};

export default Projects;
